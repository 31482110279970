import React, { FC } from "react";
import Layout from "../components/layout";

const FaqPage: FC = () => (
  <Layout pageTitle={"home"}>
    <p>FAQ</p>
  </Layout>
);

export default FaqPage;
